<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">

                <v-card class="pa-2 pa-md-4">
                    <v-card-text class="text-body-1 text--primary text-hyphenate">
                        <header>
                            <div class="text-h3 mb-2">
                                Datenschutz&shy;erklärung
                            </div>
                            <pre>
                                Service-Portal für Apotheken
                                Stand: April 2023
                                Vorgelegt von der Gesellschaft für Statistik im Gesundheitswesen mbH
                                (im nachfolgenden Dokument GFS genannt)
                            </pre>
                        </header>
                        <nav class="mb-6">
                            <header class="text-h4">
                                Inhaltsverzeichnis
                            </header>
                            <v-simple-table class="mt-2 toc" dense>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="item in toc">
                                            <td>{{ item.id }}</td>
                                            <td>
                                                <a @click="$vuetify.goTo(item.selector)">
                                                    {{ item.text }}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </nav>
                        <section>
                            <header class="text-h4">
                                Dokumenten&shy;information
                            </header>
                            <p>
                                Der Inhalt dieses Dokumentes darf weder als Ganzes noch in einzelnen Bestandteilen ohne schriftliche Genehmigung der GFS weitergegeben oder
                                in irgendeiner Form reproduziert werden. Im Interesse einer besseren Lesbarkeit wird nicht ausdrücklich in geschlechtsspezifischen
                                Personenbezeichnungen differenziert. Die gewählte männliche Form schließt alle adäquaten Formen gleichberechtigt ein.
                                Verstöße können arbeits-, zivil- oder strafrechtliche Folgen nach sich ziehen. Das Unternehmen behält sich das Recht vor, die
                                Einhaltung von Richtlinien zu überprüfen. Diese Richtlinie wird jährlich auf Vollständigkeit, Aktualität und Verbesserungsmöglichkeiten
                                überprüft. Abweichend wird eine Überprüfung und Aktualisierung erfolgen, wenn wichtige Gründe eine Überarbeitung erforderlich machen.
                            </p>
                            <p>
                                Die GFS Gesellschaft für Statistik im Gesundheitswesen mbH (im Folgenden GFS) respektiert Ihr Recht auf Datenschutz.
                                Die folgende Datenschutzerklärung erläutert, wie wir Ihre personenbezogenen Daten bei der Nutzung unseres Service-Portals für Apotheken erheben
                                und verwenden und wie Sie Ihre Datenschutzrechte ausüben können. Diese Datenschutzerklärung bezieht sich nur auf Ihre Daten im Rahmen der
                                Anmeldung und Nutzung des Service-Portals, nicht aber auf die personenbezogenen Daten Dritter, die im Rahmen der Nutzung des Service-Portals
                                von uns im Auftrag verschiedener Krankenversicherungsträger verarbeitet werden.
                            </p>
                        </section>
                        <article ref="content">
                            <section>
                                <header class="text-h4">
                                    Verantwortlicher und Daten&shy;schutz&shy;beauftragter
                                </header>
                                <section>
                                    <header class="text-h5">
                                        Verantwortliche Stelle
                                    </header>
                                    <pre>
                                        Der Verantwortliche im Sinne der EU Datenschutzgrundverordnung (im Folgenden DSGVO) ist die:

                                        GFS Gesellschaft für Statistik im Gesundheitswesen mbH
                                        Dr. Jamshid Javdani (Geschäftsführung)
                                        Edisonstraße 8
                                        85716 Unterschleißheim
                                        Deutschland
                                        Tel.: +49 89 435528-0
                                        E-Mail: info@gfs-web.de
                                        Website: www.gfs-web.de
                                    </pre>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Kontakt zum Daten&shy;schutz&shy;beauftragten
                                    </header>
                                    <pre>
                                        Der Datenschutzbeauftragte des Verantwortlichen ist:

                                        GFS Gesellschaft für Statistik im Gesundheitswesen mbH
                                        Heike Schwab (Datenschutzbeauftragte)
                                        Meißner Straße 197
                                        01445 Radebeul
                                        Deutschland
                                        Tel.: +49 351 32040-0
                                        E-Mail: datenschutz@gfs-web.de
                                        Website: www.gfs-web.de
                                    </pre>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Änderungen der Datenschutzerklärung
                                    </header>
                                    <p>
                                        Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit unter Beachtung der
                                        geltenden Datenschutzvorschriften zu ändern bzw. anzupassen.
                                    </p>
                                </section>
                            </section>
                            <section>
                                <header class="text-h4">
                                    <div class="d-flex flex-wrap">
                                        <span>Nutzung unseres Service-Portals für Apotheken&nbsp;</span>
                                        <span>(automatisch erhobene Daten)</span>
                                    </div>
                                </header>
                                <section>
                                    <header class="text-h5">
                                        Registrierung und Anmeldung
                                    </header>
                                    <p>
                                        Die Registrierung erfolgt durch postalische Zusendung eines individualisierten Codes an die Apotheke, die diesen
                                        auf einer Registrierungsseite eingibt. Der Code ist eine UUID, d. h. eine eindeutige Zeichenfolge, die nicht erraten
                                        werden kann. Wir speichern das Passwort der Apotheke nicht im Klartext, sondern nur den Hashwert. Das Passwort
                                        ist uns nicht bekannt, aus dem Hashwert ist kein Rückschluss auf das Passwort möglich.
                                    </p>
                                    <p>
                                        Bei der Anmeldung wird der Hashwert des eingegebenen Passworts mit dem bei uns abgespeicherten Hashwert
                                        verglichen. Wir werden die Apotheke niemals nach Ihrem Passwort fragen. Falls Sie von einer unbekannten Stelle oder von
                                        uns nach dem Passwort gefragt werden, kontaktieren Sie bitte unsere Datenschutzbeauftragte.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Logfiles
                                    </header>
                                    <p>
                                        Bei jedem Aufruf des Service-Portals für Apotheken übermittelt Ihr Browser folgende Daten an unseren Server:
                                    </p>
                                    <v-simple-table class="my-4 gridlines no-hover-effect center-header scroll-gradient">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Daten</th>
                                                    <th>Zweck der Verarbeitung</th>
                                                    <th>Rechtsgrundlage</th>
                                                    <th>Speicherdauer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Verwendetes Betriebssystem</td>
                                                    <td>Auswertung nach Geräten um optimierte Darstellung der Website sicherzustellen</td>
                                                    <td rowspan="7">
                                                        Vertragsanbahnung oder Vertrag gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}b DSGVO<br /><br />
                                                        Berechtigtes Interesse gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>
                                                    <td rowspan="7">
                                                        Die Daten werden gelöscht, wenn die jeweilige Sitzung beendet ist.<br /><br />
                                                        Die Löschung der IP-Adresse erfolgt nach sieben Tagen.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Informationen über den Browsertyp und die verwendete Version</td>
                                                    <td>Auswertung der verwendeten Browser um unsere Websites hierfür zu optimieren</td>
                                                </tr>
                                                <tr>
                                                    <td>Internet-Service-Provider des Nutzers</td>
                                                    <td>Auswertung der Internet Service Provider</td>
                                                </tr>
                                                <tr>
                                                    <td>IP-Adresse</td>
                                                    <td>Darstellung der Website auf dem jeweiligen Gerät</td>
                                                </tr>
                                                <tr>
                                                    <td>Datum und Uhrzeit des Aufrufs</td>
                                                    <td>Sicherstellung des ordnungsgemäßen Betriebs der Website</td>
                                                </tr>
                                                <tr>
                                                    <td>Ggf. Hersteller und Typenbezeichnung des Smartphones, Tablets oder sonstiger Endgeräte</td>
                                                    <td>Auswertung der Gerätehersteller und Arten von mobilen Endgeräten für statistische Zwecke</td>
                                                </tr>
                                                <tr>
                                                    <td>Logfiles</td>
                                                    <td>Sicherstellung des ordnungsgemäßen Betriebs der Website</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <p>
                                        Diese Daten sind für uns technisch erforderlich, um Ihnen unser Service-Portal für Apotheken anzuzeigen und die
                                        technische Stabilität und Sicherheit zu gewährleisten. Da wir damit insbesondere Schutz vor Missbrauch bieten können,
                                        sind wir zu dem Ergebnis gekommen, dass diese Daten - bei einer am Stand der Technik orientierten Gewährleistung der
                                        Datensicherheit - verarbeitet werden können, wobei Ihr Interesse an einer datenschutzkonformen Verarbeitung angemessen
                                        berücksichtigt wird. Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser
                                        Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt.
                                    </p>
                                    <p>
                                        Die Daten werden gelöscht, sobald Sie unser Service-Portal für Apotheken verlassen und damit die jeweilige Sitzung
                                        beendet ist. Die IP-Adressen werden spätestens nach sieben Tagen gelöscht.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Cookies und Tracking
                                    </header>
                                    <p>
                                        Unser Service-Portal für Apotheken verwendet nur Cookies, die unbedingt erforderlich sind, damit wir Ihnen
                                        das Service-Portal zur Verfügung stellen können. Bei Cookies handelt es sich um Textdateien, die im oder
                                        vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf,
                                        so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieses Cookie enthält eine
                                        charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen
                                        der Website ermöglicht.
                                    </p>
                                    <p>
                                        Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem 
                                        Seitenwechsel identifiziert werden kann.
                                    </p>
                                    <v-simple-table class="my-4 gridlines no-hover-effect center-header scroll-gradient">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Name des Cookies</th>
                                                    <th>Zweck der Verarbeitung</th>
                                                    <th>Rechtsgrundlage</th>
                                                    <th>Speicherdauer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Refresh Token</td>
                                                    <td>
                                                        Um ein Refresh-Token auf dem Client abzuspeichern, das es diesem erlaubt,
                                                        seine Session zu verlängern, ohne sich erneut einloggen zu müssen
                                                    </td>
                                                    <td>
                                                        Berechtigtes Interesse gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>
                                                    <td>
                                                        max. 7{{'\xa0'}}Tage oder bis zum Logout, je nachdem was zuerst eintritt
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>cookiesAccepted</td>
                                                    <td>
                                                        In diesem Cookie wird der Zeitpunkt abspeichert, zu dem der Benutzer Cookies generell zugestimmt hat
                                                    </td>
                                                    <td rowspan="3">
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>
                                                    <td>
                                                        6{{'\xa0'}}Monate
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>_GRECAPTCHA</td>
                                                    <td>
                                                        Dieser Cookie wird verwendet, um zwischen Menschen und Bots zu unterscheiden.
                                                        Dies ist vorteilhaft für die webseite, um gültige Berichte über die Nutzung ihrer webseite zu erstellen.
                                                    </td>
                                                    <!--<td>
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>-->
                                                    <td rowspan="2">
                                                        siehe <a href="https://policies.google.com/privacy?hl=de"> Datenschutzerklärung von Google</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>OTZ</td>
                                                    <td>
                                                        Diese Cookies werden von Google gesetzt, um Ihnen dessen Dienste wie zum Beispiel Google Recaptcha 
                                                        zur Verfügung zu stellen
                                                    </td>
                                                    <!--<td>
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>-->
                                                    <!--<td>
                                                        siehe <a href="https://policies.google.com/privacy?hl=de"> Datenschutzerklärung von Google</a>
                                                    </td>-->
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </section>
                            </section>
                            <section>
                                <header class="text-h4">
                                    <div class="d-flex flex-wrap">
                                        <span>Wenn Sie mit uns in Kontakt treten&nbsp;</span>
                                        <span>(mitgeteilte Daten)</span>
                                    </div>
                                </header>
                                <p>
                                    Zur Klärung von Anfragen zum Service-Portal für Apotheken können Sie mit der
                                    GFS telefonisch und schriftlich, aber auch elektronisch per E-Mail Informationen
                                    und Dokumente austauschen.
                                </p>
                                <section>
                                    <header class="text-h5">
                                        Kontaktformular per Post, per E-Mail etc.
                                    </header>
                                    <p>
                                        Sie haben die Möglichkeit, uns über unsere E-Mail-Adresse zu kontaktieren. Die uns auf diese
                                        Weise übermittelten personenbezogenen Daten werden wir ausschließlich zur Beantwortung
                                        Ihrer Anfrage verwenden.
                                    </p>
                                    <v-simple-table class="my-4 gridlines no-hover-effect center-header scroll-gradient">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Daten</th>
                                                    <th>Zweck der Verarbeitung</th>
                                                    <th>Rechtsgrundlage</th>
                                                    <th>Speicherdauer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>IP-Adresse bei Anmeldung und bei Eingang einer Email</td>
                                                    <td>Übermittlung des Formularinhalts an den Webserver</td>
                                                    <td rowspan="9">
                                                        Vertragsanbahnung oder Vertrag gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}b DSGVO<br /><br />
                                                        Berechtigtes Interesse gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>
                                                    <td rowspan="9">
                                                        Bis Ablauf handels- und steuerrechtliche Aufbewahrungsfristen, sofern einschlägig,
                                                        ansonsten bis 6{{'\xa0'}}Monate nach Beendigung der jeweiligen Konversation
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Anrede</td>
                                                    <td>Direkte Ansprache</td>
                                                </tr>
                                                <tr>
                                                    <td>Vorname</td>
                                                    <td>Direkte Ansprache</td>
                                                </tr>
                                                <tr>
                                                    <td>Nachname</td>
                                                    <td>Direkte Ansprache</td>
                                                </tr>
                                                <tr>
                                                    <td>Anliegen</td>
                                                    <td>Beantwortung des Anliegens</td>
                                                </tr>
                                                <tr>
                                                    <td>E-Mail-Adresse</td>
                                                    <td>Beantwortung des Anliegens</td>
                                                </tr>
                                                <tr>
                                                    <td>*{{'\xa0'}}Telefonnummer</td>
                                                    <td>Beantwortung des Anliegens</td>
                                                </tr>
                                                <tr>
                                                    <td>*{{'\xa0'}}Anschrift</td>
                                                    <td>Verbesserung der Abwicklung der Anfrage</td>
                                                </tr>
                                                <tr>
                                                    <td>*{{'\xa0'}}Firmenanschrift</td>
                                                    <td>Verbesserung der Abwicklung der Anfrage</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <span class="text-body-2">
                                        *{{'\xa0'}}Freiwillige Angabe
                                    </span>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Telefon
                                    </header>
                                    <p>
                                        Sie haben die Möglichkeit, uns per Telefon zu kontaktieren. Die uns auf diese
                                        Weise übermittelten personenbezogenen Daten werden wir ausschließlich zur Beantwortung
                                        Ihrer Anfrage verwenden.
                                    </p>
                                    <v-simple-table class="my-4 gridlines no-hover-effect center-header scroll-gradient">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Daten</th>
                                                    <th>Zweck der Verarbeitung</th>
                                                    <th>Rechtsgrundlage</th>
                                                    <th>Speicherdauer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Telefon- und Mobilfunknummern</td>
                                                    <td>Verarbeitung durch einen Sprachdialog oder durch die Telefonanlage</td>
                                                    <td>
                                                        Vertragsanbahnung oder Vertrag gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}b DSGVO<br /><br />
                                                        Berechtigtes Interesse gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>
                                                    <td>
                                                        Bis Ablauf handels- und steuerrechtliche Aufbewahrungsfristen
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </section>

                                <section>
                                    <header class="text-h5">
                                        Terminbuchung über SimplyBook.me
                                    </header>
                                    <p>
                                        Bitte beachten Sie bei der Buchung über SimplyBook.me die entsprechenden AGB von SimplyBook.me.
                                        Die uns auf diese Weise übermittelten personenbezogenen Daten werden wir ausschließlich zur Beantwortung Ihrer Anfrage verwenden.
                                        Bei der Buchung werden folgende Daten von ihnen erhoben:
                                    </p>
                                    <v-simple-table class="my-4 gridlines no-hover-effect center-header scroll-gradient">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>Daten</th>
                                                    <th>Zweck der Verarbeitung</th>
                                                    <th>Rechtsgrundlage</th>
                                                    <th>Speicherdauer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Institutionskennzeichen (IK)</td>
                                                    <td>Beantwortung des Anliegens</td>
                                                    <td rowspan="9">
                                                        Vertragsanbahnung oder Vertrag gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}b DSGVO<br /><br />
                                                        Berechtigtes Interesse gemäß
                                                        Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 S.{{'\xa0'}}1 lit.{{'\xa0'}}f DSGVO
                                                    </td>
                                                    <td rowspan="7">
                                                        Bis Ablauf handels- und steuerrechtliche Aufbewahrungsfristen, sofern einschlägig,
                                                        ansonsten bis 6{{'\xa0'}}Monate nach Beendigung der jeweiligen Konversation
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Vorname</td>
                                                    <td>Direkte Ansprache</td>
                                                </tr>
                                                <tr>
                                                    <td>Nachname</td>
                                                    <td>Direkte Ansprache</td>
                                                </tr>
                                                <tr>
                                                    <td>*E-Rezept ID</td>
                                                    <td>Beantwortung des Anliegens</td>
                                                </tr>
                                                <tr>
                                                    <td>E-Mail-Adresse</td>
                                                    <td>Zu Kontaktaufnahme</td>
                                                </tr>
                                                <tr>
                                                    <td>Telefonnummer</td>
                                                    <td>Zu Kontaktaufnahme</td>
                                                </tr>
                                                <tr>
                                                    <td>*{{'\xa0'}}Belegnummer</td>
                                                    <td>Beantwortung des Anliegens</td>
                                                </tr>
                                             </tbody>
                                        </template>
                                    </v-simple-table>
                                    <span class="text-body-2">
                                        *{{'\xa0'}}Freiwillige Angabe
                                    </span>
                                </section>

                            </section>
                            <section>
                                <header class="text-h4">
                                    Ihre Rechte
                                </header>
                                <p>
                                    Die folgende Auflistung umfasst alle Rechte der betroffenen Person nach der DSGVO.
                                    Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie betroffene Person im Sinne der DSGVO
                                    und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
                                </p>
                                <section>
                                    <header class="text-h5">
                                        Auskunftsrecht
                                    </header>
                                    <p>
                                        Sie können vom Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten,
                                        die Sie betreffen, von ihm verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie vom
                                        Verantwortlichen über folgende Informationen Auskunft verlangen:
                                        <ol class="my-4">
                                            <li>
                                                die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
                                            </li>
                                            <li>
                                                die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
                                            </li>
                                            <li>
                                                die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen
                                                Daten offengelegt wurden oder noch offengelegt werden;
                                            </li>
                                            <li>
                                                die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
                                                Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
                                            </li>
                                            <li>
                                                das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten,
                                                eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
                                                gegen diese Verarbeitung;
                                            </li>
                                            <li>
                                                das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
                                            </li>
                                            <li>
                                                alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der
                                                betroffenen Person erhoben werden;
                                            </li>
                                            <li>
                                                das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.{{'\xa0'}}22
                                                Abs.{{'\xa0'}}1{{'\xa0'}}und{{'\xa0'}}4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen
                                                über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung
                                                für die betroffene Person.
                                            </li>
                                        </ol>
                                        Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein
                                        Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen,
                                        über die geeigneten Garantien gem. Art.{{'\xa0'}}46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Berichtigung
                                    </header>
                                    <p>
                                        Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen,
                                        sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind.
                                        Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Einschränkung der Verarbeitung
                                    </header>
                                    <p>
                                        Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie
                                        betreffenden personenbezogenen Daten verlangen:
                                    </p>
                                    <ol class="my-4">
                                        <li>
                                            wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer bestreiten,
                                            die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
                                        </li>
                                        <li>
                                            die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
                                            stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
                                        </li>
                                        <li>
                                            der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt,
                                            Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
                                        </li>
                                        <li>
                                            wenn Sie Widerspruch gegen die Verarbeitung gemäß Art.{{'\xa0'}}21 Abs.{{'\xa0'}}1 DSGVO eingelegt 
                                            haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren 
                                            Gründen überwiegen.
                                        </li>
                                    </ol>
                                    <p>
                                        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese
                                        Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
                                        oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen
                                        Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
                                        verarbeitet werden.<br />
                                        Wurde die Einschränkung der Verarbeitung nach den o. g. Voraussetzungen eingeschränkt, werden Sie von
                                        dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Löschung
                                    </header>
                                    <section>
                                        <header class="text-h6">
                                            Löschungspflicht
                                        </header>
                                        <p>
                                            Sie können vom Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten
                                            unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich
                                            zu löschen, sofern einer der folgenden Gründe zutrifft:
                                            <ol class="my-4">
                                                <li>
                                                    Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf
                                                    sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                                                </li>
                                                <li>
                                                    Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 
                                                    lit.{{'\xa0'}}a oder Art.{{'\xa0'}}9 Abs.{{'\xa0'}}2 lit.{{'\xa0'}}a DSGVO stützte, und es fehlt an einer 
                                                    anderweitigen Rechtsgrundlage für die Verarbeitung.
                                                </li>
                                                <li>
                                                    Sie legen gem. Art.{{'\xa0'}}21 Abs.{{'\xa0'}}1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen
                                                    keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art.{{'\xa0'}}21 
                                                    Abs.{{'\xa0'}}2 DSGVO Widerspruch gegen die Verarbeitung ein.
                                                </li>
                                                <li>
                                                    Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                                                </li>
                                                <li>
                                                    Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                                                    Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
                                                    Verantwortliche unterliegt.
                                                </li>
                                                <li>
                                                    Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                                                    Informationsgesellschaft gemäß Art.{{'\xa0'}}8 Abs.{{'\xa0'}}1 DSGVO erhoben.
                                                </li>
                                            </ol>
                                        </p>
                                    </section>
                                    <section>
                                        <header class="text-h6">
                                            Information an Dritte
                                        </header>
                                        <p>
                                            Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. 
                                            Art.{{'\xa0'}}17 Abs.{{'\xa0'}}1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung 
                                            der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, 
                                            um die für die Datenverarbeitung Verantwortlichen, die die personenbezogenen Daten verarbeiten, darüber zu 
                                            informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen 
                                            Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
                                        </p>
                                    </section>
                                    <section>
                                        <header class="text-h6">
                                            Ausnahmen
                                        </header>
                                        <p>
                                            Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
                                            <ol class="my-4">
                                                <li>
                                                    zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                                                </li>
                                                <li>
                                                    zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
                                                    der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert,
                                                    oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
                                                    öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                                                </li>
                                                <li>
                                                    aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß
                                                    Art.{{'\xa0'}}9 Abs.{{'\xa0'}}2 lit.{{'\xa0'}}h{{'\xa0'}}und{{'\xa0'}}i sowie 
                                                    Art.{{'\xa0'}}9 Abs.{{'\xa0'}}3 DSGVO;
                                                </li>
                                                <li>
                                                    für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
                                                    Forschungszwecke oder für statistische Zwecke gem. Art.{{'\xa0'}}89 Abs.{{'\xa0'}}1 DSGVO,
                                                    soweit das unter Abschnitt{{'\xa0'}}a) genannte Recht voraussichtlich die Verwirklichung der Ziele
                                                    dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
                                                </li>
                                                <li>
                                                    zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                                                </li>
                                            </ol>
                                        </p>
                                    </section>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Unterrichtung
                                    </header>
                                    <p>
                                        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen
                                        geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die betreffenden personenbezogenen Daten
                                        offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
                                        es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht
                                        gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Datenübertragbarkeit
                                    </header>
                                    <p>
                                        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben,
                                        in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten
                                        einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten
                                        bereitgestellt wurden, zu übermitteln, sofern
                                        <ol class="my-4">
                                            <li>
                                                die Verarbeitung auf einer Einwilligung gem. 
                                                Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 lit.{{'\xa0'}}a DSGVO oder
                                                Art.{{'\xa0'}}9 Abs.{{'\xa0'}}2 lit.{{'\xa0'}}a DSGVO oder auf einem Vertrag gem.
                                                Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1 lit.{{'\xa0'}}b DSGVO beruht und
                                            </li>
                                            <li>
                                                die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                                            </li>
                                        </ol>
                                        In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten
                                        direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
                                        Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.<br />
                                        Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung
                                        einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                                        die dem Verantwortlichen übertragen wurde.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Widerspruchsrecht
                                    </header>
                                    <p>
                                        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
                                        der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.{{'\xa0'}}6 Abs.{{'\xa0'}}1
                                        lit.{{'\xa0'}}e{{'\xa0'}}oder{{'\xa0'}}f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                                        Bestimmungen gestütztes Profiling.<br />
                                        Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
                                        schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
                                        Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br />
                                        Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht,
                                        jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger
                                        Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.<br />
                                        Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen
                                        Daten nicht mehr für diese Zwecke verarbeitet.<br />
                                        Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet
                                        der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen 
                                        technische Spezifikationen verwendet werden.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Widerruf der daten&shy;schutz&shy;rechtlichen Einwilligung
                                    </header>
                                    <p>
                                        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
                                        der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
                                        nicht berührt.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Automatische Entscheidung im Einzelfall einschließlich Profiling
                                    </header>
                                    <p>
                                        Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling –
                                        beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher
                                        Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
                                        <ol class="my-4">
                                            <li>
                                                für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,
                                            </li>
                                            <li>
                                                aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
                                                zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie
                                                Ihrer berechtigten Interessen enthalten oder
                                            </li>
                                            <li>
                                                mit Ihrer ausdrücklichen Einwilligung erfolgt.
                                            </li>
                                        </ol>
                                        Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach 
                                        Art.{{'\xa0'}}9 Abs.{{'\xa0'}}1 DSGVO beruhen, sofern nicht Art.{{'\xa0'}}9 Abs.{{'\xa0'}}2 
                                        lit.{{'\xa0'}}a{{'\xa0'}}oder{{'\xa0'}}g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und
                                        Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.<br />
                                        Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und
                                        Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer
                                        Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                                    </p>
                                </section>
                                <section>
                                    <header class="text-h5">
                                        Recht auf Beschwerde bei der Aufsichtsbehörde
                                    </header>
                                    <p>
                                        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf
                                        Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes
                                        oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                                        personenbezogenen Daten gegen die DSGVO verstößt. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
                                        unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit
                                        eines gerichtlichen Rechtsbehelfs nach Art.{{'\xa0'}}78 DSGVO.
                                    </p>
                                </section>
                            </section>
                        </article>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'Datenschutz',
        data: () => ({
            toc: [],
        }),
        mounted: function () {

            // Ids für alle header in sections hinzufügen und Inhaltsverzeichnis (toc) automatisch bauen

            const ctx = this;
            const crawl = function (node, nodeId) {

                const children = Array.from(node.childNodes);
                const header = children.filter(n => n.nodeName === 'HEADER')[0];

                if (header) {
                    header.id = nodeId;
                    ctx.toc.push({
                        id: nodeId,
                        text: header.innerText,

                        /* Leading digits
                         * If the first character of an identifier is numeric, you’ll need to escape it based on its Unicode code point. 
                         * For example, the code point for the character 1 is U+0031, so you would escape it as \000031 or \31 .
                         * 
                         * # => Es handelt sich um einen Id-Selektor.
                         * \\3 => Escapesequenz für numerische Id.
                         * \\. => Punkte müssen ebenfalls escaped werden, damit querySelector diese nicht als Klassen interpretiert.
                         */
                        selector: '#' + '\\3' + nodeId.replaceAll('.', '\\.') + ' ',
                    });
                }

                const sections = children.filter(n => n.nodeName === 'SECTION');
                for (var i = 0; i < sections.length; i++) {
                    crawl(sections[i], [nodeId, i + 1].filter(Boolean).join('.'));
                }
            };

            crawl(this.$refs.content);
        },
    }
</script>
<style scoped>
    /*#region Textfluss*/
    pre {
        white-space: pre-line;
        margin-bottom: 16px;
        font: inherit;
        hyphens: none;
    }

    p {
        text-align: justify;
        word-break: normal;
        hyphens: auto;
    }
    /*#endregion*/

    /*#region Gliederung und Überschriften*/
    section:not(:last-of-type) {
        margin-bottom: 24px;
    }

    section :last-child {
        margin-bottom: 0px;
    }

    section > header {
        margin-bottom: 8px;
        display: flex;
    }

    section > header[id]::before {
        content: attr(id);
        min-width: 3.2rem;
    }
    /*#endregion*/

    /*#region Listen mit Gesetzestexten*/
    ol {
        counter-reset: paragraph;
        list-style: none;
        padding-left: 2em !important;
    }

    ol > li {
        position: relative;
        text-align: start;
    }

    ol > li:not(:last-of-type) {
        margin-bottom: 8px;
    }

    ol > li::before {
        content: "(" counter(paragraph) ") ";
        counter-increment: paragraph;
        position: absolute;
        left: -2em;
    }
    /*#endregion*/

    /*#region Hover-Effekt deaktivieren*/
    .no-hover-effect tr {
        background-color: transparent !important;
    }

    .no-hover-effect td:hover {
        background-color: transparent !important;
    }
    /*#endregion*/

    /*#region Gridlinien neu zeichnen*/
    .gridlines {
        border: solid thin rgba(0, 0, 0, .12);
        border-radius: 0;
    }

    .gridlines td, .gridlines th {
        border-bottom: none !important;
        padding: 4px 8px !important;
    }

    .gridlines td {
        border-top: solid thin rgba(0, 0, 0, .12) !important;
    }

    .gridlines td:not(:first-child), .gridlines th:not(:first-child) {
        border-left: solid thin rgba(0, 0, 0, .12) !important;
    }
    /*#endregion*/

    /*#region Tabellenheader zentrieren*/
    .center-header th {
        text-align: center !important;
    }
    /*#endregion*/

    /*#region Mindestbreite für Tabellenspalten*/
    th, td {
        min-width: 10em;
    }
    /*#endregion*/
</style>